<template>
    <div>
        <slot :isHovering="isHovering" :show="show" :hide="hide"></slot>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                isHovering: false
            }
        },
        methods: {
            show: function () {
                this.isHovering = true;
            },
            hide: function () {
                this.isHovering = false;
            }
        }
    }
</script>