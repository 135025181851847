<template>
    <div>
        <slot :toggle-menu="toggleMenu" :toggle="toggle"></slot>
    </div>
</template>

<script>
import { ref } from "vue";
import debounce from "lodash.debounce";

export default {
    setup() {
        const toggle = ref(false);
        const isOpen = ref(false);

        const toggleMenu = (e) => {

            if (e)
                e.preventDefault();

            let nav = document.getElementById('nav-overlay');

            if (!isOpen.value) {
                isOpen.value = true;

                if (nav)
                    nav.classList.add('is-active');

                toggle.value = true;

                setTimeout(function () {
                    if (nav)
                        nav.classList.add('start');
                }, 100);

                hideBackgroundDebounced();
            }
            else {
                toggle.value = false;

                if (nav)
                    nav.classList.remove('start');

                setTimeout(function () {
                    isOpen.value = false;

                    if (nav)
                        nav.classList.remove('is-active');
                }, 400);

                hideBackgroundDebounced.cancel();
                hideBackground(false);
            }
        };

        const hideBackground = (hide) => {
            const main = document.querySelector('.main');
            const footer = document.querySelector('.footer');
            const minaSidor = document.querySelector('.header__minasidor');

            if (hide) {
                main.style.visibility = "hidden";
                footer.style.visibility = "hidden";
                minaSidor.style.visibility = "hidden";
            } else {
                main.style.visibility = "";
                footer.style.visibility = "";
                minaSidor.style.visibility = "";
            }
        };

        const hideBackgroundDebounced = debounce(() => hideBackground(true), 600);

        return {
            toggle,
            isOpen,
            toggleMenu
        }
    }
}

</script>