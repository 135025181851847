<template>
    <div>
        <slot :startTour="startTour"></slot>
    </div>
</template>

<script>
    import { DESKTOP_MODE, getCurrentModeFromWidth } from '../display-mode.js';
    import introJs from 'intro.js';
    export default {
        data: function () {
            return {
                opened: false
            };
        },
        props: {

        },
        mounted() {
            //var that = this;

            //window.addEventListener('resize', function (e) {
            //    that.doExitResize();
            //});
        },
        methods: {
            //doExitResize() {
            //    console.log("resize: " + this.opened);
            //    if (this.opened) {
            //        this.opened = false;
            //        introJs().exit();
            //    }
            //},

            startTour: function (e) {
                e.preventDefault();

                this.opened = true;

                let intro = introJs();
                var overlay;


                intro.setOption("nextLabel", "Nästa >")
                    .setOption("prevLabel", "< Bakåt")
                    .setOption("doneLabel", "&nbsp;")
                    .setOption("skipLabel", "Avsluta")
                    .setOption("showStepNumbers", true)
                    .start();

            },

            startTourCompany: function (e) {
                e.preventDefault();

                this.opened = true;

                let intro = introJs();
                var overlay;

                intro.onafterchange(function (targetElement) {

                    var allowedMode = DESKTOP_MODE;
                    var activeMode = getCurrentModeFromWidth(window.innerWidth);


                    if (this._currentStep === 1) {
                        overlay = document.getElementsByClassName("introjs-fixedTooltip");

                        for (var i = 0; i < overlay.length; i++) {

                            if (overlay[i].style) {

                                overlay[i].classList.remove('lonevisning');
                                overlay[i].classList.remove('lonevisning-mobile');
                            }
                        }
                    }

                    if (this._currentStep === 0) {
                        overlay = document.getElementsByClassName("introjs-fixedTooltip");

                        for (var i = 0; i < overlay.length; i++) {

                            if (overlay[i].style) {
                                if (allowedMode === activeMode) {
                                    overlay[i].classList.add('lonevisning');
                                }
                                else {
                                    overlay[i].classList.add('lonevisning-mobile');
                                }
                            }
                        }
                    }

                });

                intro.setOption("nextLabel", "Nästa >")
                    .setOption("prevLabel", "< Bakåt")
                    .setOption("doneLabel", "Klart!")
                    .setOption("skipLabel", "Avsluta")
                    .setOption("showStepNumbers", true)
                    .start();
            },

            startTourPerson: function (e) {
                e.preventDefault();

                this.opened = true;

                let intro = introJs();
                var overlay;

                intro.onafterchange(function (targetElement) {

                    var allowedMode = DESKTOP_MODE;
                    var activeMode = getCurrentModeFromWidth(window.innerWidth);

                    if (this._currentStep === 1) {
                        overlay = document.getElementsByClassName("introjs-fixedTooltip");

                        for (var i = 0; i < overlay.length; i++) {

                            if (overlay[i].style) {

                                overlay[i].classList.remove('lonevisning');
                                overlay[i].classList.remove('lonevisning--person');
                                overlay[i].classList.remove('lonevisning-mobile');
                            }
                        }
                    }

                    if (this._currentStep === 0) {
                        overlay = document.getElementsByClassName("introjs-fixedTooltip");

                        for (var i = 0; i < overlay.length; i++) {

                            if (overlay[i].style) {
                                if (allowedMode === activeMode) {
                                    overlay[i].classList.add('lonevisning');
                                    overlay[i].classList.add('lonevisning--person');
                                }
                                else {
                                    overlay[i].classList.add('lonevisning-mobile');
                                }
                            }
                        }
                    }

                });

                intro.setOption("nextLabel", "Nästa >")
                    .setOption("prevLabel", "< Bakåt")
                    .setOption("doneLabel", "Klart!")
                    .setOption("skipLabel", "Avsluta")
                    .setOption("showStepNumbers", true)
                    .start();
            }

        }

    }
</script>


