import * as Sentry from '@sentry/browser';
import axios from 'axios';

Sentry.init({
  dsn: "https://463b6b61481be927d0da27ee4d38470b@o4507068231909376.ingest.us.sentry.io/4507133912809472",
  enabled: window.location.host === 'www.ratsit.se',
  allowUrls: [
    'ratsit.se',
  ],
  ignoreErrors: [
    'undefined is not an object (evaluating', // This is a Safari bug with JSON-LD. Full error is "undefined is not an object (evaluating 'r["@context"].toLowerCase')"

    // We get this a lot in Firefox (usually when fetching wisepop script). Since we are not using fetch API it is likely safe to ignore this until we do.
    'NetworkError when attempting to fetch resource',
    'TypeError: Load failed', 

    'Failed to fetch', // We see this in various browsers when fetching 3rd party scripts. Since we are not using fetch API it is likely safe to ignore this until we do.
    'Cannot redefine property: websredir', // Reoccuring error, could be user browser extension or something else. Not something we can fix.
    'UnhandledRejection: Non-Error promise rejection captured with value: undefined', // Seen mostly in Safari. A little bit dangerous to ignore, since it could hide real errors. But it is too noisy to keep.
  ],
});


export const logErrorToSentry = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    // If the request was made and the server responded.
    // We want to avoid sending network errors to Sentry.
    if (error.response) {
      Sentry.captureException(error);
    } else {
      Sentry.addBreadcrumb({
        category: 'xhr',
        message: 'Network error',
        level: 'warning',
        data: {
          url: error.config?.url,
        }
      });
    }
  } else {
    Sentry.captureException(error);
  }
}