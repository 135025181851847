<template>
    <div>
        <slot :toggle="toggle" :active="active"></slot>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                active: false
            };
        },
        props: {

        },
        mounted() {
        },
        methods: {
            toggle() {
                this.active = !this.active;
            }
        }

    }
</script>


<style type="text/css">
    .devtoolbar {
        position: fixed !important;
        top: auto !important;
        bottom: 0 !important;
        left: 0;
        background-color: #f3f8fa;
        opacity: 0.8;
        z-index: 5000 !important;
        max-height: 80px;
        transition: 0.4s ease-in-out;
        overflow: hidden;
        width: 20px;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
        font-weight: 500;
        line-height: 1.58;
    }

.devtoolbar:hover {
    opacity: 1 !important;
}

.devtoolbar.is-active {
    max-height: 600px;
    width: 100%;
}

.devtoolbar-container {
    padding: 5px 30px 10px 10px;
}

.devtoolbar.is-active .devtoolbar-container {
    display: block;
}

@media (max-width:768px) {
    .devtoolbar .nav-primary-items {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100px;
        padding-bottom: 20px;
    }

    .devtoolbar .nav-primary-items .nav-item {
        float: left;
    }
}

    .devtoolbar__button {
        position: absolute;
        right: -23px;
        -ms-transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        top: 28px;
        font-size: 11px;
        cursor: pointer;
        text-transform: uppercase;
    }

.devtoolbar-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 500px;
    margin-bottom: 10px;
    overflow: hidden;
    overflow-x: auto;
    margin-bottom: 10px;
}

.devtoolbar-list li {
    margin: 0;
    padding: 0;
    margin-left: 10px;
    list-style-type: none;
    margin-top: 15px;
    margin-right: 15px;
}

    .devtoolbar-list li a {
        color: black;
        text-decoration: underline;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    }

.devtoolbar-list .no-list {
    list-style-type: none;
}
</style>