export const MOBILE_MODE = 'MOBILE_MODE';
export const TABLET_MODE = 'TABLET_MODE';
export const DESKTOP_MODE = 'DESKTOP_MODE';

export function getCurrentModeFromWidth(width) {
    let changedTo = DESKTOP_MODE;
    if (width < 768)
        changedTo = MOBILE_MODE;
    if (width >= 768 && width < 992) {
        changedTo = TABLET_MODE;
    }
    return changedTo;
}
