const MIN_WINDOW_WIDTH = 980;

export default {
    bind(el) {
        if (window.innerWidth > MIN_WINDOW_WIDTH) {
            window.addEventListener('load',
                function (e) {
                    onResize(el, window.innerWidth);
                });

            window.addEventListener('resize', function (e) {
                onResize(el, e.target.innerWidth);
            }, false);

            el.addEventListener('DOMNodeInserted', function (e) {
                onResize(el, e.target.innerWidth);
            }, false);
        }
     
    }
}

function onResize(el, browserWidth) {
    
    var scaleWidth = el.parentElement.clientWidth / el.clientWidth;
    if (scaleWidth > 1)
        scaleWidth = 1;

    const bannerWidth = 980;

    //console.log(el.parentElement.clientWidth + " " + el.clientWidth);

    if (el.parentElement.clientWidth >= bannerWidth){
        el.style.transform = `none`;
        el.style.overflow = 'initial';
        el.style.transformOrigin = 'initial';
        el.style.position = 'static';
        return;
    }

    if (scaleWidth < 1) {
        el.style.transform = `scale(${scaleWidth})`;
        el.style.transformOrigin = 'initial';
        el.style.position = 'relative';
        el.style.overflow = 'initial';
        return;
    }

}
