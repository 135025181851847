import './startpage.scss';
import "core-js/stable";
import "regenerator-runtime/runtime";

import 'root/src/js/shared/outline.js';

import { createApp } from 'vue'
const app = createApp({});

import SearchFieldComponent from "root/src/js/shared/v-input-search.vue";
app.component('v-input-search', SearchFieldComponent);

import ScaleThisAd from 'root/src/js/shared/v-scale-this-ad.js';
app.directive('scale-this-ad', ScaleThisAd);

import SearchHome from './v-search-home.vue';
app.component('v-search-home', SearchHome);

import TourButton from 'root/src/js/shared/v-tour-button.vue';
app.component('v-tour-button', TourButton);

import DevToolbar from 'root/src/js/shared/v-dev-toobar.vue';
app.component('v-dev-toolbar', DevToolbar);

import LayoutPlugin from 'root/src/js/shared/layout/layout-plugin';
app.use(LayoutPlugin);

app.mount('#app');

