import MenuComponent from './v-menu.vue';
import MenuCloseComponent from './v-menu-close.vue';
import FooterLogoAnimationComponent from './v-footer-logo-animation.vue';
import InputSearchCenterComponent from './v-input-search-center.vue';

var LayoutPlugin = {
    install(Vue) {
        Vue.component('v-menu', MenuComponent);
        Vue.component('v-menu-close', MenuCloseComponent);
        Vue.component('v-footer-logo-animation', FooterLogoAnimationComponent);
        Vue.component('v-input-search-center', InputSearchCenterComponent);
    }
}

export default LayoutPlugin;