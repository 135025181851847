<template>
    <div>
        <slot :checkPosition="checkPosition"></slot>
    </div>
</template>
<script>
    export default {
        mounted() {
            window.addEventListener('scroll', (event) => {
                this.checkPosition();
            });
            this.checkPosition();
        },
        methods: {
            checkPosition: function () {
                var body = document.body;

                if (window.scrollY > 100) {
                    body.classList.add('fade-header');
                }
                else {
                    body.classList.remove('fade-header');
                }
            }

        }
    }
</script>