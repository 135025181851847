<template>
    <div>
        <button aria-label="Stäng meny" class="header-menu__close" @click="handleChange($event)"></button>
    </div>
</template>

<script>
export default {
    emits: ['closemenu'],
    setup(props, { emit }) {

        const handleChange = (e) => {
            e.preventDefault();
            e.stopPropagation();
            emit('closemenu');
        }

        return {
            handleChange
        }

    }
}
</script>
