<template>
    <div>
        <slot :isFocused="isFocused"
              :hasValue="hasValue"
              :focus="focus"
              :blur="blur"
              :clear="clear"
              :inputChange="inputChange"
              :showClosebutton="showClosebutton"
              :hideCloseButton="hideCloseButton"
              :vemRef="vem"
        ></slot>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                isFocused: false,
                hasValue: false,
                showClosebutton: false,
                vem: null
            };
        },
        props: {

        },
        mounted() {
            var that = this;
            setTimeout(function () {
                that.init();
            }, 100);
        },

        methods: {
            init: function () {
                // var field = this.$scopedSlots.default()[0].context.$refs.vem;
                var field = this.vem;

                if (field) {
                    if (field.value.length > 0) {
                        this.hasValue = true;
                        field.focus();
                        //console.log('har v�rde');
                    }
                }
            },

            focus: function () {
                this.isFocused = true;
            },

            blur: function (e) {
                var field = e.currentTarget; //this.$scopedSlots.default()[0].context.$refs.vem;

                if (field) {
                    if (field.value.length > 0) {
                        this.hasValue = true;
                    }
                    else {
                        this.hasValue = false;
                    }
                }

                if (!this.hasValue) {
                    this.isFocused = false;
                }

                this.hideCloseButton();
            },

            hideCloseButton: function () {
                this.showClosebutton = false;
            },

            clear: function (e) {
                var field = e.currentTarget.previousElementSibling;
                field.value = "";
                field.focus();
            },

            inputChange: function (e) {
                e.preventDefault();
                this.showClosebutton = e.currentTarget.value.length > 0 ? true : false;
           /*     console.log(this.showClosebutton);*/
            }

        }

    }
</script>