<template>
    <div class="w-100 d-lg-flex justify-content-lg-center">
        <slot :clear="clear"
              :inputChange="inputChange"
              :hideCloseButton="hideCloseButton"
              :showClosebutton="showClosebutton"></slot>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                showClosebutton: false
            };
        },
        methods: {
            hideCloseButton: function () {
                this.showClosebutton = false;
            },

            clear: function (e) {                
                var field = e.currentTarget.previousElementSibling; 
                field.value = "";
                field.focus();
            },

            inputChange: function (e) {
                this.showClosebutton = e.currentTarget.value.length > 0 ? true : false; 
               // console.log(this.showClosebutton);
            }


        }

    }
</script>